import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import EditPermissionDialog from '@/components/users/permission/EditPermissionDialog.vue';
import { permissionsTableHeadersA, permissionsTableHeadersB } from '@/assets/js/tableHeader.js';

export default {
	name: 'Permissions',

	data: () => ({
		headers: permissionsTableHeadersA,
		headersB: permissionsTableHeadersB,

		userSelected: null,
		companySelected: null,
		applicationSelected: null,
		profileSelected: null,
		managementSelected: null,

		managementsTable: [],
		profilesTable: [],

		loadingSave: false,
		loadingUsers: false,
		loadingProfile: false,
		isLoadingTable: false,
		loadingManagements: false,

		timeout: null,
		search: null,
		dataDialog: null,
		unsavedConfirmed: false,
		isUpdatePermissions: false,
	}),

	computed: {
		user() {
			return this.$store.getters['users/getUser'] || null;
		},
		users() {
			let usersList = this.$store.getters['users/getUsers'] || [];
			if (this.user?.id) {
				usersList.push({ ...this.user });
			}
			return usersList;
		},
		companies() {
			return this.$store.getters['companies/getCompaniesList'];
		},
		applications() {
			return this.$store.getters['applications/getApplicationsList'];
		},
		profiles() {
			return this.filterProfiles(this.$store.getters['profiles/getProfilesList']);
		},
		validationErrors() {
			return this.$store.getters['permissions/getValidationErrors'];
		},
		managements() {
			let items = [];
			const managements = this.$store.getters['managements/getManagementsList'];
			if (Array.isArray(managements) && managements.length > 0) {
				items = [{ id: null, name: 'Todas las gerencias' }];
				items = items.concat(managements);
			}
			return this.filterManagemets(items);
		},
		user_id() {
			return this.$route?.params?.id ? parseInt(this.$route?.params?.id) : null;
		},
		enableBtnSave() {
			return !!(this.userSelected && this.managementsTable.length > 0 && this.profilesTable.length > 0);
		},
		isActiveBtnProfile() {
			return !!(this.userSelected && this.companySelected && this.managements?.length > 0);
		},
		isActiveBtnAccess() {
			return !!(this.applicationSelected && this.profileSelected);
		},
	},

	created() {
		this.initialize();
	},

	methods: {
		initialize() {
			this.getUser(this.user_id);
			this.$store.dispatch('companies/getCompaniesList');
			this.$store.dispatch('applications/getApplicationsList');
		},

		async getUser(user_id) {
			if (user_id) {
				try {
					this.profilesTable = [];
					this.loadingUsers = true;
					await this.$store.dispatch('users/getUser', {
						user_id: user_id,
					});
					const user = (await this.$store.state.users.user) || null;
					await this.$store.dispatch('users/addUser', user);
					this.userSelected = user_id;
					this.getPermissions();
				} catch (error) {
				} finally {
					this.loadingUsers = false;
				}
			}
		},

		async getPermissions() {
			this.unsavedConfirmed = false;
			if (this.userSelected && this.companySelected) {
				try {
					this.isLoadingTable = true;
					const params = {
						user_id: this.userSelected,
						company_id: this.companySelected,
					};
					await this.$store.dispatch('permissions/getPermissionsByUser', params);
					this.setTables();
				} finally {
					this.isLoadingTable = false;
				}
			}
		},

		setTables() {
			this.managementsTable = this.$store.getters['permissions/getAccessPermission'] || [];
			this.profilesTable = this.$store.getters['permissions/getProfilesPermission'] || [];
		},

		filterManagemets(items = []) {
			const filtered = items.filter((item) => {
				const index = this.managementsTable.findIndex((e) => e.management_id == item.id);
				return !(index >= 0);
			});
			return filtered;
		},

		filterProfiles(items = []) {
			let filtered = items.filter((item) => {
				let index = this.profilesTable.findIndex((e) => e.profile_id == item.id);
				return !(index >= 0);
			});
			return filtered;
		},

		async listenChangeCompany() {
			this.applicationSelected = null;
			this.profileSelected = null;
			this.managementSelected = null;
			this.unsavedConfirmed = false;
			this.getPermissions();
			try {
				this.loadingManagements = true;
				await this.$store.dispatch('managements/getManagementsList', {
					company_id: this.companySelected,
					actives: true
				});
			} finally {
				this.loadingManagements = false;
			}
		},

		async listenChangeApplication() {
			try {
				this.loadingProfile = true;
				this.profileSelected = null;
				await this.$store.dispatch('profiles/getProfilesByApplication', {
					application_id: this.applicationSelected,
				});
			} finally {
				this.loadingProfile = false;
			}
		},

		listenChangeManagement() { },

		async validateBeforeAddingManagement() {
			if (this.managementSelected) {
				const hasChildElementsNull = this.profilesTable.some((profile) => {
					return profile.costCenters.some((ceco) => ceco?.management_id == null);
				});
				if (hasChildElementsNull) {
					const { isConfirmed } = await this.$swal.fire({
						title: '¿Desea asignar una gerencia?',
						text: 'Se eliminará el ítem "Todas las gerencias"',
						icon: 'question',
						showCancelButton: true,
						cancelButtonColor: '#e0e0e0',
						confirmButtonColor: '#0039a6',
						cancelButtonText: 'No',
						confirmButtonText: 'Sí',
						reverseButtons: true,
					});
					if (isConfirmed) {
						this.removeManagement({ management_id: null });
						this.addTableA();
					}
				} else {
					this.addTableA();
				}
			} else {
				this.addTableA();
			}
		},

		// addTableA() {
		// 	if (this.userSelected && this.companySelected && this.managements.length > 0) {
		// 		const foundManagement = this.managements.find((e) => e.id == this.managementSelected);
		// 		const data = {
		// 			management_id: foundManagement?.id,
		// 			management_name: foundManagement?.name,
		// 		};

		// 		const hasSameItem = this.managementsTable.some((elem) => {
		// 			const checkManagement = elem.management_id == data.management_id;
		// 			return checkManagement;
		// 		});

		// 		if (hasSameItem) {
		// 			this.showSnack('warning', 'No se acepta duplicidad');
		// 		} else {
		// 			if (data?.management_id) {
		// 				const index = this.managementsTable.findIndex((e) => e.management_id === null);
		// 				if (index >= 0) this.managementsTable.splice(index, 1);
		// 			} else {
		// 				this.managementsTable = [];
		// 			}
		// 			this.managementsTable.push(data);
		// 			this.managementSelected = null;
		// 			this.isUpdatePermissions = true;
		// 			this.unsavedConfirmed = false;
		// 		}
		// 	} else {
		// 		this.showSnack('warning', 'Datos incompletos');
		// 	}
		// },

		addTableA() {
		
			if (this.userSelected && this.companySelected && this.managements.length > 0) {
				const foundManagement = this.managements.find((e) => e.id == this.managementSelected);
				const data = {
					management_id: foundManagement?.id,
					management_name: foundManagement?.name,
				};

				if (!data.management_id && data.management_name === 'Todas las gerencias') {
				
					this.managements.splice(0, 1)

					for (let management of this.managements) {
						this.managementsTable.push({
							management_id: management?.id,
							management_name: management?.name
						})
					}
				} else {

					const hasSameItem = this.managementsTable.some((elem) => {
						const checkManagement = elem.management_id == data.management_id;
						return checkManagement;
					});
				
					if (hasSameItem) {
						this.showSnack('warning', 'No se acepta duplicidad');
					} else {
						if (data?.management_id) {
							const index = this.managementsTable.findIndex((e) => e.management_id === null);
							if (index >= 0) this.managementsTable.splice(index, 1);
						} else {
							this.managementsTable = [];
						}
						this.managementsTable.push(data);
						this.managementSelected = null;
						this.isUpdatePermissions = true;
						this.unsavedConfirmed = false;
					}
				}

			} else {
				this.showSnack('warning', 'Datos incompletos');
			}
		},

		addTableB() {
			const foundApplication = this.applications.find((e) => e.id == this.applicationSelected);

			const foundProfile = this.profiles.find((e) => e.id == this.profileSelected);
			const data = {
				application_id: foundApplication?.id,
				application_name: foundApplication?.name,
				profile_id: foundProfile?.id,
				profile_name: foundProfile?.name,
				costCenters: [],
			};
			const hasSameItem = this.profilesTable.some((elem) => {
				const checkApplication = elem?.application_id == data?.application_id;
				const checkProfile = elem?.profile_id == data?.profile_id;
				return checkApplication && checkProfile;
			});

			if (hasSameItem) {
				this.showSnack('warning', 'No se acepta duplicidad');
			} else {
				this.profilesTable.push(data);
				this.profileSelected = null;
				this.isUpdatePermissions = true;
				this.unsavedConfirmed = false;
			}
		},

		async verifyManagementsToDelete(item) {
			const hasChildElements = this.profilesTable.some((profile) => {
				return profile.costCenters.some((ceco) => ceco?.management_id == item.management_id);
			});
			if (hasChildElements) {
				const { isConfirmed } = await this.$swal.fire({
					title: '¿Estás seguro de que quieres eliminar?',
					text: 'Tienes centro de costos asignados a esta gerencia',
					icon: 'question',
					showCancelButton: true,
					cancelButtonColor: '#0039a6',
					confirmButtonColor: '#e0e0e0',
					cancelButtonText: 'No',
					confirmButtonText: 'Sí',
				});
				if (isConfirmed) {
					this.removeManagement(item);
				}
			} else {
				this.removeManagement(item);
			}
		},

		async verifyProfilesToDelete(item) {
			if (Array.isArray(item?.costCenters) && item?.costCenters?.length > 0) {
				const { isConfirmed } = await this.$swal.fire({
					title: '¿Estás seguro de que quieres eliminar?',
					text: 'Tienes centro de costos asignados a este perfil',
					icon: 'question',
					showCancelButton: true,
					cancelButtonColor: '#0039a6',
					confirmButtonColor: '#e0e0e0',
					cancelButtonText: 'No',
					confirmButtonText: 'Sí',
				});
				if (isConfirmed) {
					this.removeProfile(item);
				}
			} else {
				this.removeProfile(item);
			}
		},

		removeManagement(item) {
			if (item) {
				this.profilesTable = this.profilesTable.map((e) => {
					let costCenters = [];
					if (e?.costCenters && Array.isArray(e?.costCenters)) {
						costCenters = e.costCenters.filter((e) => e.management_id != item.management_id);
					}
					return { ...e, costCenters: costCenters };
				});
				const index = this.managementsTable.findIndex((elem) => elem.management_id === item.management_id);
				if (index >= 0) this.managementsTable.splice(index, 1);
				this.isUpdatePermissions = true;
				this.unsavedConfirmed = false;
			}
		},

		removeProfile(item) {
			if (item) {
				const index = this.profilesTable.findIndex(
					(elem) => elem.profile_id === item.profile_id && elem.application_id === item.application_id
				);
				if (index >= 0) this.profilesTable.splice(index, 1);
				this.isUpdatePermissions = true;
				this.unsavedConfirmed = false;
			}
		},

		_find(items = [], propertyName = '', value = null) {
			let data = null;
			if (Array.isArray(items) && items.length > 0 && propertyName) {
				data = items.find((element) => element[propertyName] == value);
			}
			return data;
		},

		async checkAccess() {
			
			const foundManagement = this._find(this.managementsTable, 'management_id', null);
		
			if (foundManagement) {
				
				const { isConfirmed } = await this.$swal.fire({
					title: '¿Desea continuar?',
					text: 'Usted esta a punto de dar acceso a todas las gerencias',
					icon: 'question',
					showCancelButton: true,
					cancelButtonColor: '#e0e0e0',
					confirmButtonColor: '#0039a6',
					cancelButtonText: 'No',
					confirmButtonText: 'Sí',
					reverseButtons: true,
				});
				if (isConfirmed) {
				
					this.savePermission();
				}
			} else {
				
				this.savePermission();
			}
		},

		async savePermission() {
			try {
				this.loadingSave = true;
			
				await this.$store.dispatch('permissions/savePermissions', {
					user_id: this.userSelected,
					company_id: this.companySelected,
					managements: this.managementsTable,
					profiles: this.profilesTable,
				});
				this.isUpdatePermissions = false;
				this.showSnack('success', 'Permisos asignados satisfactoriamente');
			} catch (error) {
				this.showSnack('red', 'No se puedo realizar la operación');
			} finally {
				this.loadingSave = false;
			}
		},

		showSnack(color = '', text = '') {
			$EventBus.$emit('showSnack', { color, text });
		},

		listenSearch(value) {
			clearTimeout(this.timeout);
			if (value?.length > 1 || value == '') {
				this.timeout = setTimeout(() => {
					if (this.user.name != value) {
						this.$store.dispatch('users/getUsers', {
							search: value,
							active: 1
						});
					}
				}, 600);
			}
		},

		openDialog(item) {
			if (this.managementsTable?.length > 0) {
				this.dataDialog = { ...item };
			} else {
				this.showSnack('warning', 'Agregar gerencias a la lista');
			}
		},

		updateAccess({ profile_id, costCenters }) {
			this.isUpdatePermissions = true;
			this.unsavedConfirmed = false;
			this.showSnack('success', 'Centro de costos asignados');
			this.profilesTable = this.profilesTable.map(function (e) {
				if (e.profile_id === profile_id) {
					return { ...e, costCenters };
				}
				return e;
			});
		},

		async checkNewChanges() {
			if (this.isUpdatePermissions && !this.unsavedConfirmed) {
				const { isConfirmed } = await this.$swal.fire({
					title: 'Tienes cambios sin guardar',
					text: '¿Deseas continuar?',
					icon: 'question',
					showCancelButton: true,
					cancelButtonColor: '#0039a6',
					confirmButtonColor: '#e0e0e0',
					cancelButtonText: 'No',
					confirmButtonText: 'Sí, continuar',
				});
				if (isConfirmed) {
					this.unsavedConfirmed = true;
				}
			}
		},
	},
	components: {
		ValidationAlert,
		EditPermissionDialog,
	},
};
